<template>
    <router-view :key="$route.path"></router-view>
</template>
  
  <script>  
  export default {
    methods: {
      
    },
    mounted() {
      
    },
  }
  </script>